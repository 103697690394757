'use client';

import { usePathname } from 'next/navigation';
import { PAGE_TYPES, usePageType } from '@vaimo/google-tag-manager';
import { useUserContext } from '@/lib/context';

const NotFoundClientIntegrations = () => {
    const [{ isSignedIn }] = useUserContext();
    const pathname = usePathname();

    usePageType({ isSignedIn, pageType: PAGE_TYPES.NOT_FOUND, pathname });

    return null;
};

export default NotFoundClientIntegrations;
