export const getRelativeUrlPath = (path?: string) => {
    if (!process.env.NEXT_APP) return path;

    if (!path || (path.startsWith('/') && !path.startsWith('//'))) return path;
    try {
        const url = new URL(path);
        return url.pathname + url.search;
    } catch (e) {
        return path;
    }
};
