import { generateSizes, generateSrcset, makeUrlWithParams } from './helpers';
import type { StaticImport } from 'next/dist/shared/lib/get-img-props';

interface IGenerateImageProps {
    fit?: string;
    height?: number | `${number}`;
    isOptimizedByFastly: boolean;
    isSetWidth?: boolean;
    quality?: number | `${number}`;
    ratio?: number;
    sizesSet?: Map<string | number, number>;
    src: string | StaticImport;
    width?: number | `${number}`;
    widths?: Map<string | number, number>;
}

export const generateImageProps = ({
    fit,
    height,
    isOptimizedByFastly,
    isSetWidth,
    quality,
    ratio,
    sizesSet,
    src,
    width,
    widths,
    ...rest
}: IGenerateImageProps) => {
    if (!isOptimizedByFastly || typeof src !== 'string') {
        return {
            height,
            src,
            width,
            ...rest,
        };
    }

    const fileReference = makeUrlWithParams(src, { fit, height, quality, width });

    return {
        height,
        sizes: sizesSet ? generateSizes(sizesSet) : '',
        src: fileReference,
        srcSet: widths ? generateSrcset(src, widths, ratio, isSetWidth) : '',
        width,
        ...rest,
    };
};
