'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import Container from '@/components/Container';
import NotFoundImage from '@/public/images/404.png';
import OptimizedImage from '@/components/OptimizedImage';
import NotFoundClientIntegrations from '@/components/NotFoundPage/notFoundClientIntegrations';
import { useResourceUrl } from '@/hooks/useResourceUrl';
import Button from '@/components/Button';
import Link from '@/components/Link';
import classes from './notFound.module.scss';

/**
 *  IMPORTANT! This component has to be a client-component, since it is a part of not-found page.
 *  Nextjs includes not-found pages content to every page, even if a page doesn't have any error.
 *  As a result, it might have RSC payload (for server components) of 404 page in initial html, which bots might crawl (including image links).
 *  To avoid such situations, we make component a client component, to move the code from RSC payload to JS.
 */

const NotFound = () => {
    const t = useTranslations('notFoundPage');
    const resourceUrl = useResourceUrl();
    const notFoundTitleLocalized = t('uhOhText');
    const takeMeHomeTitleLocalized = t('takeMeHome');

    return (
        <>
            <Container variant="small" className={classes.pageContainer}>
                <h1>
                    {notFoundTitleLocalized}
                    <br />
                    {t('cantFindPage')}
                </h1>
                <p>{t('description')}</p>
                <Button title={takeMeHomeTitleLocalized}>
                    <Link href={resourceUrl('/')}>{takeMeHomeTitleLocalized}</Link>
                </Button>
                <figure>
                    <OptimizedImage src={NotFoundImage} alt={notFoundTitleLocalized} priority />
                    <figcaption>{t('photoCaption')}</figcaption>
                </figure>
                <NotFoundClientIntegrations />
            </Container>
        </>
    );
};

export default NotFound;
