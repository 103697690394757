/**
 * Convert locale from react-intl BCP 47 language code, to Magento standard
 *
 * @param {string} str
 * @returns {string} A locale string (e.g. `fr_FR`).
 */
export const toMagentoLocale = (str: string): string => str.replace('-', '_');

/**
 * Convert locale from Magento standard to react-intl BCP 47 language code
 *
 * @param {string} str
 * @returns {string} A string (e.g. `fr-FR`).
 */
export const toReactLocale = (str: string): string => str.replace('_', '-');

export const storeCodeToLocale = (storeCode: string): string | undefined => {
    if (!storeCode) {
        return;
    }

    const [language, country] = storeCode?.split('_') || [];

    return [language, country.toUpperCase()].join('-');
};
