'use client';

import React, { ButtonHTMLAttributes, forwardRef, MouseEvent, ReactElement, Ref } from 'react';

import clsx from 'clsx';
import classes from './button.module.scss';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    dataCustomId?: string;
    isFullWidth?: boolean;
    isSmall?: boolean;
    onClick?: (e: any) => void;
    priority?: string;
    variant?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'link' | 'trigger' | 'disabled';
}

export const Button = forwardRef(
    (
        {
            children,
            className,
            dataCustomId,
            disabled,
            isFullWidth,
            isSmall,
            onClick,
            priority,
            type,
            variant,
            ...restProps
        }: ButtonProps,
        ref: Ref<HTMLButtonElement>,
    ): ReactElement => {
        const buttonClasses = clsx(
            className,
            (variant === 'disabled' || disabled) && classes.disabled,
            isFullWidth && classes.isFullWidth,
            isSmall && classes.isSmall,
            variant === 'link' && classes.link,
            (priority ? priority === 'high' || priority === 'normal' : variant === 'primary' || !variant) &&
                classes.primary,
            variant === 'quaternary' && classes.quaternary,
            (variant === 'secondary' || priority === 'low') && classes.secondary,
            variant === 'tertiary' && classes.tertiary,
            variant === 'trigger' && classes.trigger,
        );

        const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
            onClick?.(event);
        };

        return (
            <button
                className={buttonClasses}
                data-custom-id={dataCustomId}
                onClick={handleClick}
                type={type}
                disabled={disabled}
                ref={ref}
                {...restProps}
            >
                {children}
            </button>
        );
    },
);

Button.displayName = 'Button';

export default Button;
