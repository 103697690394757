import Image from 'next/image';
import type { ImageProps } from 'next/image';
import React, { forwardRef, ReactElement } from 'react';
import { generateImageProps } from '@/components/OptimizedImage/utils/generateImageProps';

interface IOptimizedImageProps extends ImageProps {
    fit?: string;
    isImgTag?: boolean;
    isSetWidth?: boolean;
    sizesSet?: Map<string | number, number>;
    widths?: Map<string | number, number>;
}

const OptimizedImage = forwardRef<HTMLImageElement, IOptimizedImageProps>(
    (
        { alt, fit, height, isImgTag, quality, sizesSet, src, unoptimized = false, width, widths, ...props },
        ref,
    ): ReactElement | null => {
        if (!src) return null;

        const imageProps = generateImageProps({
            fit,
            height,
            isOptimizedByFastly: unoptimized || !!isImgTag,
            quality,
            sizesSet,
            src,
            width,
            widths,
            ...props,
        });

        return isImgTag ? (
            // eslint-disable-next-line @next/next/no-img-element
            <img
                ref={ref}
                alt={alt}
                loading="lazy"
                decoding={'async'}
                {...(imageProps as JSX.IntrinsicElements['img'])}
            />
        ) : (
            <Image alt={alt} unoptimized={unoptimized} {...imageProps} />
        );
    },
);

OptimizedImage.displayName = 'OptimizedImage';

export default OptimizedImage;
