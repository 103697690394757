import { getRelativeUrlPath } from '@vaimo-int/aem-pwa-common-components/dist/utils/getRelativeUrlPath';

export const UNCONSTRAINED_SIZE_KEY = 'default';

const getFileType = (pathname: string): string => {
    const fileName = pathname.split('/').reverse()[0];

    return fileName.split('.').reverse()[0];
};

export const makeUrlWithParams = (path: string, { ...options } = {}): string => {
    if (!Object.keys(options)?.length) {
        return path;
    }

    const [pathname, searchParams] = (getRelativeUrlPath(path) as string).split('?');
    const params = new URLSearchParams(searchParams);
    params.set('auto', 'webp');

    // use png/pjpg if webp is not available
    const imageFileType = getFileType(pathname);

    if (imageFileType === 'png') {
        params.set('format', 'png');
    } else {
        params.set('format', 'pjpg');
    }

    Object.entries(options).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
            params.set(key, value.toString());
        }
    });

    return `${pathname}?${params.toString()}`;
};

export const generateSizes = (sizes: Map<string | number, number>): string => {
    const result: string[] = [];

    for (const [breakpoint, width] of sizes) {
        if (breakpoint !== UNCONSTRAINED_SIZE_KEY) {
            result.push(`(max-width: ${breakpoint}px) ${width}vw`);
        }
    }

    // Add the unconstrained size at the end.
    result.push(`${sizes.get(UNCONSTRAINED_SIZE_KEY)}px`);

    return result.join(', ');
};

export const generateSrcset = (
    imageURL: string,
    widths: Map<string | number, number>,
    ratio: number = 1,
    isSetWidth: boolean = true,
) => {
    if (!imageURL) return '';

    return Array.from(widths)
        .map(([descriptor, width]) => {
            const url = makeUrlWithParams(imageURL, {
                height: Math.round(width / ratio),
                ...(isSetWidth && { width }),
            });
            const widthDescription = typeof descriptor === 'string' ? descriptor : descriptor + 'w';

            return `${url} ${widthDescription}`;
        })
        .join(',\n');
};
