import React, { forwardRef, ReactElement, ReactNode, Ref } from 'react';
import clsx from 'clsx';
import defaultClasses from './container.module.scss';

export type ContainerVariant = 'small' | 'xsmall' | 'yotpo' | 'newCategoryPage';

interface ContainerProps {
    children: ReactNode;
    className?: string;
    id?: string;
    variant?: ContainerVariant;
}

const Container = forwardRef(
    ({ children, className, id, variant }: ContainerProps, ref: Ref<HTMLDivElement>): ReactElement => {
        const classes = clsx(
            className,
            variant === 'newCategoryPage' ? defaultClasses.newRoot : defaultClasses.root,
            variant && defaultClasses[variant],
        );

        return (
            <div className={classes} ref={ref} id={id}>
                {children}
            </div>
        );
    },
);

Container.displayName = 'Container';

export default Container;
