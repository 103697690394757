'use client';

import { useLocale } from 'next-intl';
import { toMagentoLocale } from '@/utils/i18n/formatLocale';

export const useResourceUrl = (): ((url: string) => string) => {
    const locale = useLocale();
    const storeCode = toMagentoLocale(locale)?.toLowerCase();

    return (url: string): string => {
        const urlWithSlash = url.startsWith('/') ? url : '/' + url;

        if (url.startsWith('https://')) {
            return url;
        }

        return `/${storeCode}${urlWithSlash}`;
    };
};
